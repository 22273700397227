<div class="row modal-bar">
  <div class="col-sm-6">
      <h5 class="modal-title">{{ 'DIALOGS.EDIT-EVENT-LABEL.TITLE' | translate}}</h5>
  </div>
  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">

  <div class="section-wrapper">

    <div class="form-layout">

      <ng-container>
          <div class="row mg-b-25">
            
            <div class="col-lg-3">
              <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.CODE' | translate}}<span class="tx-danger">*</span></label>
                <input class="form-control" name="Code" formControlName="Code" placeholder="{{'DIALOGS.EDIT-EVENT-LABEL.FORM.CODE' | translate}}">
                <t4-display-validation controlName="Code" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
    
            <div class="col-lg-3">
              <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.NAME' | translate}} <span class="tx-danger">*</span></label>
                <input class="form-control" name="Name" formControlName="Name" placeholder="{{'DIALOGS.EDIT-EVENT-LABEL.FORM.NAME' | translate}}">
                <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
    
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.DISPLAYTEXT' | translate}}<span class="tx-danger">*</span></label>
                <input class="form-control" name="DisplayText" formControlName="DisplayText" placeholder="{{'DIALOGS.EDIT-EVENT-LABEL.FORM.DISPLAYTEXT' | translate}}">
                <t4-display-validation controlName="DisplayText" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
    
        </div>
      </ng-container>
    

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="ckbox">
              <input type="checkbox" (change)="updateDisplayShow($event.currentTarget.checked)" name="Show" formControlName="Show" ><span class="ml-1">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.SHOW' | translate}}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="displayShow">
        <div class="col-6">
          <t4-date-time-control formControlName="ShowFrom" [required]="false" [labelDate]="'DIALOGS.EDIT-EVENT-LABEL.FORM.SHOW-FROM'" [labelTime]="'DIALOGS.EDIT-EVENT-LABEL.FORM.SHOW-FROM-TIME'"></t4-date-time-control>
          <t4-display-validation controlName="ShowFrom" [form]="form"[messages]="validationMessages"></t4-display-validation>
        </div>
        <div class="col-6">
          <t4-date-time-control formControlName="ShowTill" [required]="false" [labelDate]="'DIALOGS.EDIT-EVENT-LABEL.FORM.SHOW-TILL'" [labelTime]="'DIALOGS.EDIT-EVENT-LABEL.FORM.SHOW-TILL-TIME'"></t4-date-time-control>
          <t4-display-validation controlName="ShowTill" [form]="form"[messages]="validationMessages"></t4-display-validation>
        </div>
      </div>

      <ng-container>
        <div class="row">    
          <div class="col-lg-3">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.ICON-CODE' | translate}}</label>
              <input class="form-control" (input)="onIconCodeChange($event)" name="IconCode" formControlName="IconCode" placeholder="fa-regular fa-calendar">
              <t4-display-validation controlName="IconCode" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-1">
            <div class="form-group">
              <label class="form-control-label"><i *ngIf="iconCode !== null" class="icon-behind {{ iconCode }}"></i></label>
            </div>
          </div>
    
          <div class="col-lg-4">
            <div class="form-group">
              <input type="checkbox" [disabled]="isSystemLabel" [(ngModel)]="hasForeColor" [ngModelOptions]="{standalone: true}"><span class="ml-1">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.CUSTOM-FORECOLOR' | translate}}</span>
              <div *ngIf="hasForeColor" class="color-picker-display" [(colorPicker)]="foreColor" [style.background]="foreColor">&nbsp;</div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <input type="checkbox" [disabled]="isSystemLabel" [(ngModel)]="hasBackgroundColor" [ngModelOptions]="{standalone: true}"><span class="ml-1">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.CUSTOM-BACKGROUNDCOLOR' | translate}}</span>
              <div *ngIf="hasBackgroundColor" class="color-picker-display" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor">&nbsp;</div>
            </div>
          </div>
        </div>
    
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE' | translate }} <span class="tx-danger">*</span></label>
              <ng-select  [items]="detailTypeValue" 
                          [disabled]="!isAdditionalInfo"
                          bindLabel="viewValue" 
                          bindValue="value" 
                          [(ngModel)]="selectedDetailType" 
                          [ngModelOptions]="{standalone: true}" 
                          placeholder="{{'DIALOGS.EDIT-EVENT-LABEL.FORM.DETAIL-TYPE' | translate}}"
                          [clearable]="false" 
                          notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" 
                          appendTo="body">
              </ng-select>
            </div>
          </div> 
        </div>
    
        <div class="row" *ngIf="selectedDetailType == 'detail-text'">    
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.DETAILTEXT' | translate}}</label>
              <quill-editor [readOnly]="isInArchiveMode" [(ngModel)]="richTextContent" [ngModelOptions]="{standalone: true}" [sanitize]="true" [modules]="config" name="richTextContent" [styles]="{height: '200px'}"></quill-editor> 
    
            </div>
          </div>
        </div>
    
        <div class="row" *ngIf="selectedDetailType == 'external-link'">    
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-EVENT-LABEL.FORM.EXTERNAL-LINK' | translate}}</label>
              <input class="form-control" name="ExternalLink" formControlName="ExternalLink" placeholder="{{'DIALOGS.EDIT-EVENT-LABEL.FORM.EXTERNAL-LINK' | translate}}">
              <t4-display-validation controlName="ExternalLink" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>
      </ng-container>
    
      <div class="form-layout-footer row">
        <div class="col-6">
          <button *ngIf="!isInArchiveMode" (click)="save()" [disabled]="!form.valid" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(null);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>
</form>


