/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CrossSellProductEventType } from './crossSellProductEventType';


export interface CrossSellProductEventModel { 
    OnSaleFrom?: Date;
    OnSaleTill?: Date;
    CategoryId?: number;
    EventId?: number;
    EventType?: CrossSellProductEventType;
    GroupingId?: string | null;
    ProductId?: number;
    ProductVariants?: Array<number> | null;
    IsNew?: boolean;
    TenantShopId?: number;
}

