import { HttpLoaderService } from '@tymes4-shared';
import { KeyValuePair } from '../../models/key-value-pair';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantShop, TenantShopService } from '../../api';

@Component({
    selector: 'app-tenant-shop-form',
    templateUrl: './edit-tenant-shop-form.component.html'
})
export class EditTenantShopComponent implements OnInit {
    public form: FormGroup;

    public registrationChecked: boolean = false;
    public saving = false;

    private isNew: boolean = false;
    private editObject: TenantShop = null;

    validationMessages = [];

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {

        if (this.requiredFields === null) {
          this.requiredFields = {};
        }

        if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {

          const form = targetForm ? targetForm : this.form;
          const formField = form.get(field);

          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
      }

    constructor(
      @Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef:  MatDialogRef<EditTenantShopComponent>,
      private loader:HttpLoaderService,
      private tenantShopService: TenantShopService,
    ) { }

    ngOnInit() {
      this.editObject = this.passedData.payload;


      if (this.editObject == null) {
        this.isNew = true;
        this.editObject = {
          Id: 0,
          Subdomain: ""
        };
      }

      this.form = new FormGroup({
        Id: new FormControl(0),
        Name: new FormControl(null, [Validators.required]),
        Subdomain: new FormControl(null),
        Active: new FormControl(false),
        IsBusiness: new FormControl(false),
        IsRegistrationOpenForNewMembers: new FormControl(null),
        RegistrationOpenForNewMembers: new FormControl(null)
      });

      this.form.patchValue(this.editObject);

      this.form.value.IsRegistrationOpenForNewMembers = !this.editObject.RegistrationOpenForNewMembers ? false : true;

      this.form.patchValue(this.form.value);
    }
    
    submit() {
      this.form.value.RegistrationOpenForNewMembers = this.form.value.IsRegistrationOpenForNewMembers ?  this.form.value.RegistrationOpenForNewMembers : null;
      let formData = this.form.value;
      

      this.saving = true;
      this.loader.open();
      if (this.isNew)
      {
        this.tenantShopService.createTenantShop(formData).subscribe((id:number) => {
          this.dialogRef.close(id);
          this.loader.close();
        })
      }
      else {
        this.tenantShopService.updateTenantShop(formData).subscribe(() => {
          this.dialogRef.close(this.editObject.Id);
          this.saving = false;
          this.loader.close();
        })
      }

    }
}
