/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NavigationItemEnum } from './navigationItemEnum';
import { NavigationItemCategoryEnum } from './navigationItemCategoryEnum';


export interface NavigationItem { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Category?: NavigationItemCategoryEnum;
    Item?: NavigationItemEnum;
    ParentId?: number;
    Weight?: number;
    Title?: string | null;
    SubTitle?: string | null;
    Link?: string | null;
    RedirectMethod?: string | null;
    Icon?: string | null;
    StatusIcon?: string | null;
    Style?: string | null;
    TenantShopId?: number;
}

