import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventShopLabelModel, EventShopLabelService } from '../../../../api';
import { HttpLoaderService } from '@tymes4-shared';
import { first } from 'rxjs/operators';
import { SnackbarHelper } from '../../../../helpers/snackbar-helper';

@Component({
  selector: 'app-edit-label-details',
  templateUrl: 'edit-label-details.component.html', 
  styleUrls: ['edit-label-details.component.scss']
})

export class EditLabelDetailsComponent implements OnInit {

  validationMessages = {}

  public config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, 'link'],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['clean'],
    ]
  };

  private editingEventShopLabel : EventShopLabelModel | null = null;

  public form: FormGroup;
  public displayShow = false;
  public isAdditionalInfo = false;

  public detailTypeValue: Array<any>;
  public selectedDetailType: 'none' | 'external-link' | 'detail-text' = 'none';
  public iconCode: string | null= null;
  public hasForeColor: boolean = false;
  public hasBackgroundColor: boolean = false;
  public foreColor: any;
  public backgroundColor: any;
  public isSystemLabel: boolean = false;

  public richTextContent: string;
  public isInArchiveMode: boolean = false;
  public key: string;
  public eventId: number;
  public activeTenantShopId: number;

  constructor(
              @Inject(MAT_DIALOG_DATA) public passedData: any,
              private translateService: TranslateService,
              private loader: HttpLoaderService,
              private snackbarHelper: SnackbarHelper,
              private eventShopLabelService: EventShopLabelService,
              public dialogRef: MatDialogRef<EditLabelDetailsComponent>
            ) { }

  ngOnInit() {
    //this.eventId = this.passedData.payload.eventId;

    this.detailTypeValue  = [
      { value: 'none', viewValue: this.translateService.instant('CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE.NONE') },
      { value: 'external-link', viewValue: this.translateService.instant('CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE.EXTERNAL-LINK') },
      { value: 'detail-text', viewValue: this.translateService.instant('CUSTOM.BUTTON.STATE.FORM.ACTION-TYPE.OVERLAY') },
    ];

    var iconInput = new FormControl('');

    this.form = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      IconCode: iconInput,
      DisplayText: new FormControl('', [Validators.required]),
      DetailText: new FormControl(''),
      ExternalLink: new FormControl(''),
      Show: new FormControl(false),
      ShowFrom: new FormControl(null),
      ShowTill: new FormControl(null),
    });

    if (this.passedData.item && this.passedData.item !== null)
    {
      this.loadExistingLabel(this.passedData.item.Id);
    }

    this.eventId = this.passedData.eventId;
    this.activeTenantShopId = this.passedData.activeTenantShopId;
  }

  onIconCodeChange(e) {

    this.iconCode = (e.target as HTMLInputElement).value;
    
    if (this.iconCode == '') 
      this.iconCode = null;
  }

  updateDisplayShow(checked) {
    this.displayShow = checked;
  }


  save() {

    var objectToStore = this.form.value;

    if (!objectToStore.Show) {
      objectToStore.ShowFrom = null;
      objectToStore.ShowTill = null;
    }

    if (this.editingEventShopLabel !== null) {
        objectToStore.Id = this.editingEventShopLabel.Id;
    }
    
    objectToStore.DetailText = this.selectedDetailType == 'detail-text' ? this.richTextContent : null;
    objectToStore.ExternalLink = this.selectedDetailType == 'external-link' ? objectToStore.ExternalLink : null;
    objectToStore.CustomForeColor = this.hasForeColor ? this.foreColor : null;
    objectToStore.CustomBackgroundColor = this.hasBackgroundColor ? this.backgroundColor : null;
    objectToStore.EventId = this.eventId;
    objectToStore.TenantShopId = this.activeTenantShopId;

    var observable = this.editingEventShopLabel !== null ? 
      this.eventShopLabelService.updateShopLabel(objectToStore) : 
      this.eventShopLabelService.addShopLabel(objectToStore);

    this.loader.open();

    observable
      .pipe(first())
      .subscribe(result => {
        this.loader.close();
        this.snackbarHelper.saved();
        this.dialogRef.close(true);
      });
  }

  
loadExistingLabel(id: any) {

  this.loader.open();

  this.eventShopLabelService
      .getShopLabelTypeById(id)
      .subscribe(label => {

        this.editingEventShopLabel = label;
        
        if (label.IsSystemLabel) {
          //remove the required fields for editing the system labels
          this.form.controls["Code"].disable();
          this.form.controls["Name"].disable();

          if (label.EventShopLabelType === 'AdditionalInfo') {
            this.isAdditionalInfo = true;
          }
          else {
            this.form.controls["DisplayText"].disable();
            label.DisplayText = "-";
          }
        }

        this.form.patchValue(label);

        if (label.CustomForeColor !== null && label.CustomForeColor !== '')
        {
          this.hasForeColor = true;
          this.foreColor = label.CustomForeColor;
        }

        if (label.CustomBackgroundColor !== null && label.CustomBackgroundColor !== '')
        {
          this.hasBackgroundColor = true;
          this.backgroundColor = label.CustomBackgroundColor;
        }

        this.displayShow = label.Show;
        this.richTextContent = label.DetailText;
        this.isSystemLabel = label.IsSystemLabel;

        if (label.DetailText !== null)
          this.selectedDetailType = 'detail-text';
        else if (label.ExternalLink !== null)
          this.selectedDetailType = 'external-link';
        else 
          this.selectedDetailType = 'none';

        this.loader.close();
      });
}


}

