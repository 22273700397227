<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-9">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-3">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
      <div class="form-layout">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.NAME' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="name" formControlName="Name" placeholder="{{'TENANT-SHOPS.EDITDIALOG.FORM.PLACEHOLDER.NAME' | translate}}" maxlength="256">
              <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>
          <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="form-control-label">{{'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.SUBDOMAIN' | translate}} <span class="tx-danger">*</span></label>
                  <input class="form-control" name="subdomain" formControlName="Subdomain" placeholder="{{'TENANT-SHOPS.EDITDIALOG.FORM.PLACEHOLDER.SUBDOMAIN' | translate}}" maxlength="63">
                  <t4-display-validation controlName="Subdomain" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
              </div>
          </div>
          <div class="row">
            <div fxFlex class="mt-1 col-lg-12">
              <input type="checkbox" name="active" formControlName="Active" class="mr-2">
              <label class="form-check-label" for="active">{{'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.ACTIVE' | translate}}</label>
            </div>
          </div>
          <div class="row">
            <div fxFlex class="mt-1 col-lg-12">
              <input type="checkbox" name="isBusiness" formControlName="IsBusiness" class="mr-2">
              <label class="form-check-label" for="active">{{'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.ISBUSINESS' | translate}}</label>
            </div>
          </div>

          <div class="row">
            <div fxFlex class="mt-1">
              <input type="checkbox" class="mr-2" formControlName="IsRegistrationOpenForNewMembers">
              <label class="form-check-label" for="active">{{'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.REGISTRATION-CHECK' | translate}}</label>
            </div>
          </div>

          <div class="col-lg-12" *ngIf="this.form.value.IsRegistrationOpenForNewMembers">
            <t4-date-time-control formControlName="RegistrationOpenForNewMembers" [labelDate]="'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.REGISTRATION-DATE'" [labelTime]="'TENANT-SHOPS.EDITDIALOG.FORM.LABEL.REGISTRATION-TIME'"></t4-date-time-control>
          </div>

          <div class="form-layout-footer row">
            <div class="col-6">
              <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
                class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
            </div>
            <div class="col-6">
              <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
                class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
            </div>

          </div><!-- form-layout-footer -->
      </div>
  </div>
</form>
