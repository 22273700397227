/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventDetailsArgs { 
    EventTypeId?: number;
    Name?: string | null;
    ImageId?: number | null;
    HomeImageId?: number | null;
    AwayImageId?: number | null;
    NameHomeTeam?: string | null;
    NameAwayTeam?: string | null;
    IdentificationRequired?: boolean;
    IdentificationRequiredUponPurchase?: boolean;
    StartDateTime?: Date;
    EndDateTime?: Date;
    Classification?: string | null;
    Named?: boolean;
    AssigningRatio?: number | null;
    IsUniquelyAssigned?: boolean;
}

