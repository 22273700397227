import {Component, Inject, Input, ViewChild, OnInit, Output, EventEmitter} from '@angular/core';
import {EventSaleCategoryState} from '../../models/custom-button-data';
import {ConfirmService} from '@tymes4-shared';
import { CustomButtonStateListComponent } from "app/shared/components/manage-event-sale-category-states/custom-button-state-list/custom-button-state-list.component";
import { EventService, SaleCategory, TenantShop, TenantShopService } from '../../api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-manage-event-sale-category-states',
  templateUrl: './manage-event-sale-category-states.component.html',
  styleUrls: ['./manage-event-sale-category-states.component.scss']
})
export class ManageEventSaleCategoryStatesComponent implements OnInit {
  
  @ViewChild(CustomButtonStateListComponent) customButtonStateListComponent: CustomButtonStateListComponent;
  @ViewChild('CustomButtonStateList') customButtonStateList;


  scStates = null;
  eventSaleCategories: SaleCategory[] | null = null;

  @Output() onSave = new EventEmitter();
  @Input() eventId: number = null;

  
  public statesInCurrentTab: Array<EventSaleCategoryState>;
    public tenantShopList: TenantShop[] = [];
    public activeTenantShop: TenantShop;

  currentTabId = null;

  constructor(public confirmService: ConfirmService, private eventService: EventService, private tenantShopService: TenantShopService) {
  }

  ngOnInit() {
    if (this.eventId != null)  {
      forkJoin([
        this.eventService.getEventSaleCategoryForEvent(this.eventId),
        this.tenantShopService.listAllTenantShops()
      ]).subscribe(([categories, tenantShops]: [SaleCategory[], TenantShop[]]) => {
        this.eventSaleCategories = categories;
        this.tenantShopList = tenantShops;
        this.activeTenantShop = this.tenantShopList[0];
        this.customButtonStateListComponent.setActiveShop(this.activeTenantShop.Id);
  
        if (this.eventSaleCategories.length > 0) {
          this.currentTabId = this.eventSaleCategories[0].Id;
        }
      });
    }
  }

  setActive(tenantShopId: number) {
    this.activeTenantShop = this.tenantShopList.find(x => x.Id == tenantShopId);
    this.customButtonStateListComponent.setActiveShop(this.activeTenantShop.Id);
  }

  refresh() {
    
    if (this.currentTabId !== null) {
      this.customButtonStateListComponent.setEvent(this.eventId, this.currentTabId);
    }
  }

  saveSaleCategories() {
    this.onSave.emit();
  }
  onTabChange(e) {

    this.currentTabId = e.selectedTab.id;
    this.customButtonStateListComponent.setEvent(this.eventId, this.currentTabId); //currenttab === salescategoryid
    this.customButtonStateListComponent.setActiveShop(this.activeTenantShop.Id);
  }

  public getFilter(saleCategoryId: number): Function{
    return s => s.SaleCategoryId === saleCategoryId;
  }

  public getBaseState(saleCategoryId: number): EventSaleCategoryState{
    const baseState = new EventSaleCategoryState();
    baseState.SaleCategoryId = saleCategoryId;
    baseState.EventId = this.eventId;
    return baseState;
  }
}
