import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {HttpLoaderService, PagedResult } from '@tymes4-shared';
import { ActionType, ActionTypeDictionary, ButtonStyle, ButtonStyleDictionary, CustomButtonData } from '../../../models/custom-button-data';
import {ConfirmService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { EventService, FrontendButtonState, FrontendButtonStateList, SalesPeriodService } from '../../../api';
import { first } from 'rxjs/operators';
import { CustomButtonStateFormComponent } from '../custom-button-state-form/custom-button-state-form.component';
import { event } from 'jquery';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-custom-button-state-list',
  templateUrl: './custom-button-state-list.component.html',
  styleUrls: ['custom-button-state-list.component.scss'],
})
export class CustomButtonStateListComponent implements OnInit, AfterViewInit {
  // for listview
  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 12,
    noItemsText: this.translate.instant('COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.CUSTOM-BUTTON-STATE-LIST.NO-ITEMS-TEXT'),
    multiSelectMode: false,
    disableSort: true,
    headers: [
      { sortField: null, label: 'CUSTOM.BUTTON.STATE.LIST.HEADER.TEXT', width: 230 },
      { sortField: null, label: 'CUSTOM.BUTTON.STATE.LIST.HEADER.ACTION', width: 120 },
      { sortField: null, label: 'CUSTOM.BUTTON.STATE.LIST.HEADER.STYLE', width: 120 },
      { sortField: null, label: ''},
      { sortField: null, label: 'CUSTOM.BUTTON.STATE.LIST.HEADER.START-DATE', width: 150 },
      { sortField: null, label: 'CUSTOM.BUTTON.STATE.LIST.HEADER.END-DATE', width: 150 },
      { label: '' }

    ]
  };
  // end for listview

  public eventId: number | null = null;
  public saleCategoryId: number | null = null;
  public ActiveTenantShopId: number;

  @Input() public salesPeriodId: number | null = null;

  public displayOverlappingPeriodsWarning = false;
  public buttonStates: Array<FrontendButtonState> | null = null;

  // private _states: Array<CustomButtonData>;
  public buttonStyleValues: Array<any>;
  public actionTypeValues: Array<any>;
  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(private dialog: MatDialog,
              public confirmService: ConfirmService,
              private salesPeriodService: SalesPeriodService,
              private eventService: EventService,
              private translate: TranslateService,
              private loader: HttpLoaderService,
              private authService: AuthService) {}

  public ngOnInit(): void {
    this.buttonStyleValues = ButtonStyleDictionary;
    this.actionTypeValues = ActionTypeDictionary;

  }

  public setActiveShop(tenantShopId: number) {
    this.ActiveTenantShopId = tenantShopId;
    this.listVw.refreshData();
  }

  public setEvent(eventId: number, saleCategoryId: number) {

    this.eventId = eventId;
    this.saleCategoryId = saleCategoryId;
    this.listVw.refreshData();
  }


  public ngAfterViewInit() {
    this.listVw.refreshData();
  }

  OnDataRequest(e) {

    if (this.eventId === null && this.salesPeriodId === null) return;

    var observable = this.eventId !== null ?
      this.eventService.getEventSaleCategoryStateForEvent(this.eventId, this.saleCategoryId, this.ActiveTenantShopId) :
      this.salesPeriodService.getSalesPeriodButtonStates(this.salesPeriodId);

    this.loader.open();

    observable
      .pipe(first())
      .subscribe((statesModel) => {

        this.loader.close();

        this.displayOverlappingPeriodsWarning = statesModel.OverlappingPeriods;
        this.buttonStates = statesModel.ButtonStates;

        const data : PagedResult = {
          TotalRecords: this.buttonStates.length,
          Records: this.buttonStates
        };
        
        this.listVw.Data = data;

      });


  }

  addNewState() {
    
    const dialogRef: MatDialogRef<any> = this.dialog.open(CustomButtonStateFormComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.ADD.NEW')
        , payload: {}}
    });
    dialogRef.afterClosed().subscribe((data: FrontendButtonState) => {
      
      data.TenantShopId = this.ActiveTenantShopId;
      if (data !== null) {


        var observable = this.eventId !== null ?
          this.eventService.createEventSaleCategoryButtonState(this.eventId, this.saleCategoryId, data) :
          this.salesPeriodService.createSalesPeriodButtonState(this.salesPeriodId, data);

        this.loader.open();

        observable
          .pipe(first())
          .subscribe(response => {
            //new button state added. Store 
            this.loader.close();
            this.listVw.refreshData();      
          })        
      }
    });
  }

  modifyExistingState(state: CustomButtonData) {
  
    const dialogRef: MatDialogRef<any> = this.dialog.open(CustomButtonStateFormComponent, {
      width: '1024px',
      disableClose: true,
      data: { title: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.EDIT'), payload: state }
    });
    dialogRef.afterClosed().subscribe((data: FrontendButtonState) => {
      
      if (data !== null) {

        var observable = this.eventId !== null ?
          this.eventService.updateEventSaleCategoryButtonState(this.eventId, this.saleCategoryId, data.Id, data) :
          this.salesPeriodService.updateSalesPeriodButtonState(this.salesPeriodId, data.Id, data);

        this.loader.open();

        observable
          .pipe(first())
          .subscribe(response => {
            //new button state added. Store 
            this.loader.close();
            this.listVw.refreshData();      
          })        
      }
    });
  }

  deleteState(state: FrontendButtonState) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE')
    }).subscribe((r: boolean) => {
      if (r) {
        
        this.loader.open();

        var observable = this.eventId !== null ?
          this.eventService.deleteEventSaleCategoryButtonState(state.Id, this.eventId, this.saleCategoryId) :
          this.salesPeriodService.deleteSalesPeriodButtonState(this.salesPeriodId, state.Id)

        observable
          .pipe(first())
          .subscribe(d => {
            this.loader.close();
            this.listVw.refreshData();
          });
      }
    });
  }

  public saveButtonStates() {
    // this.onSave.emit();
    // this.hasModifiedStates = false;
  }

  getButtonActionType(item) {
    if(item.ActionType === null) {
      return '-';
    } else if(item.ActionType == -1) {
      return this.translate.instant('GENERIC.ACTION-TYPE.DEFAULT');
    } else {
      return item.ActionType; 
    }
  }

  getButtonStyle(item) {
    if(item.ButtonStyle === null) {
      return '-';
    } else if(item.ButtonStyle == -1) {
      return this.translate.instant("GENERIC.BUTTON-STYLE.DEFAULT");
    } else {
      return item.ButtonStyle;
    }
  }
}
