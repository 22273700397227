import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { CrossSellProductEventValidator } from "./crosssellproductevent.validator";
import {CrossSellProductCategoryService, CrossSellProductService, CrossSellProductVariantService, TenantShop} from "../../api";
import { AuthService } from "../../services/auth.service";



@Component({
    selector: 'app-edit-crosssell-product-event',
    templateUrl: './edit-crosssell-product-event.component.html'
})
export class EditCrossSellProductEventComponent implements OnInit {

    public selectedEventId;
    public selectedEventType;

    public crossSellCategory: any;
    public categories: any;
    public products: any;
    public variants:  any;

    public isNew: boolean;
    public form: FormGroup;
    public systemManaged: false;
    public activeTenantShopId: number;
    private isInArchiveMode = this.authService.isInArchiveMode();

    validationMessages = [];

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
                public dialogRef: MatDialogRef<EditCrossSellProductEventComponent>,
                private crossSellProductCategoryService: CrossSellProductCategoryService,
                private crossSellProductService: CrossSellProductService,
                private crossSellProductVariantService: CrossSellProductVariantService,
                private translate: TranslateService,
                private authService: AuthService
                ) { }


    ngOnInit() {
      this.isNew = this.passedData.isNew;
      this.selectedEventId = this.passedData.eventId;
      this.selectedEventType = this.passedData.eventType;
      this.activeTenantShopId = this.passedData.activeTenantShop.Id;

      const o = {
        EventId: this.passedData.eventId,
        EventType: this.passedData.eventType,
      };

    this.form = new FormGroup({
        CategoryId: new FormControl(''),
        ProductId: new FormControl(''),
        ProductVariants: new FormControl('', {validators: Validators.required}),
        OnSaleFrom: new FormControl('', { validators: Validators.required }),
        OnSaleTill: new FormControl('',{ validators: Validators.required }),
        GroupingId: new FormControl(''),
        EventId: new FormControl(''),
        EventType: new FormControl(''),
        IsNew: new FormControl(''),
        TenantShopId: new FormControl(this.activeTenantShopId)
     } ,CrossSellProductEventValidator.ValidateDates);



      this.crossSellProductCategoryService.crossSellProductCategoryGetAll().subscribe((categories: any) => {
            this.categories = categories;

        });

      if (this.isNew) {
        this.form.patchValue(o);
      }
      else{
        this.form.patchValue(o);

        var values;

        this.crossSellProductVariantService.getByGroupingId(this.passedData.payload.GroupingId).subscribe((data: any) => {

            if(data){
                //populate dropdowns
                this.updateProducts(data[0].CategoryId);
                this.updateVariants(data[0].ProductId);


                var activeFromTime = moment(data[0].OnSaleFrom).format("HH:mm");
                var activeTillTime = moment(data[0].OnSaleTill).format("HH:mm");
                var activeFromDate = moment(data[0].OnSaleFrom);
                var activeTillDate = moment(data[0].OnSaleTill);

                //apply values
                const patches = {
                    CategoryId: data[0].CategoryId,
                    ProductId: data[0].ProductId,
                    ProductVariants: data.map(x=>x.VariantId),
                    GroupingId: data[0].GroupingId,
                    OnSaleFrom: data[0].OnSaleFrom, 
                    OnSaleTill: data[0].OnSaleTill, 
                };
                this.form.patchValue(patches);
            }
        });
      }
      if(this.isInArchiveMode)
        this.form.disable()
    }


    submit() {
        const frm = this.form.value;

        frm.IsNew = this.isNew;

        // this.loader.open();
        if (this.isNew) {
          this.crossSellProductVariantService.createEventVariants(frm).subscribe((id: any) => {
            // close this dialog and pass the newly added id to the called
            this.dialogRef.close(id);
            // this.loader.close();
          });
        } else {
          this.crossSellProductVariantService.updateEventVariants(frm).subscribe((id: any) => {
            this.dialogRef.close(frm.GroupingId);
          });
        }
    }

    onCategoryChange(item){
        // get products for cat
        this.form.controls.ProductId.setValue(null);
        this.form.controls.ProductVariants.setValue(null);
        if(item.Id!=null){
           this.updateProducts(item.Id);
        }
    }

    updateProducts(categoryId){
        this.crossSellProductService.getCrossSellProductByCategory(categoryId).subscribe((products: any) => {
            this.products = products;
        });
    }

    onProductChange(item){
        // get event variants
        this.form.controls.ProductVariants.setValue(null);
        if(item.Id!=null){
          this.updateVariants(item.Id);
        }
    }

    updateVariants(productId){

        this.crossSellProductVariantService.getByProduct(productId).subscribe((variants: any) => {
            this.variants = variants;
        });
    }



}
