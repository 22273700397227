/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NavigationItemEnum = 'None' | 'MyProfile' | 'Distributix' | 'FanManagement' | 'TermsAndConditions' | 'FAQ' | 'MyPassepartout' | 'SeasonCard' | 'Memberships' | 'Reservation' | 'Overview' | 'MatchdayPasses' | 'MyOrders' | 'FamilyFriends' | 'Identification' | 'GoTo' | 'ChangePassword' | 'LogOff' | 'MyAccount' | 'Welcome' | 'TicketSalesInformation' | 'IdentifyNotification' | 'TicketExchange' | 'Help' | 'MyTickets' | 'TicketShop' | 'BusinessTicketshop' | 'LogIn' | 'TicketExchangeChild' | 'Dynamic' | 'MyFlexplan';

export const NavigationItemEnum = {
    None: 'None' as NavigationItemEnum,
    MyProfile: 'MyProfile' as NavigationItemEnum,
    Distributix: 'Distributix' as NavigationItemEnum,
    FanManagement: 'FanManagement' as NavigationItemEnum,
    TermsAndConditions: 'TermsAndConditions' as NavigationItemEnum,
    Faq: 'FAQ' as NavigationItemEnum,
    MyPassepartout: 'MyPassepartout' as NavigationItemEnum,
    SeasonCard: 'SeasonCard' as NavigationItemEnum,
    Memberships: 'Memberships' as NavigationItemEnum,
    Reservation: 'Reservation' as NavigationItemEnum,
    Overview: 'Overview' as NavigationItemEnum,
    MatchdayPasses: 'MatchdayPasses' as NavigationItemEnum,
    MyOrders: 'MyOrders' as NavigationItemEnum,
    FamilyFriends: 'FamilyFriends' as NavigationItemEnum,
    Identification: 'Identification' as NavigationItemEnum,
    GoTo: 'GoTo' as NavigationItemEnum,
    ChangePassword: 'ChangePassword' as NavigationItemEnum,
    LogOff: 'LogOff' as NavigationItemEnum,
    MyAccount: 'MyAccount' as NavigationItemEnum,
    Welcome: 'Welcome' as NavigationItemEnum,
    TicketSalesInformation: 'TicketSalesInformation' as NavigationItemEnum,
    IdentifyNotification: 'IdentifyNotification' as NavigationItemEnum,
    TicketExchange: 'TicketExchange' as NavigationItemEnum,
    Help: 'Help' as NavigationItemEnum,
    MyTickets: 'MyTickets' as NavigationItemEnum,
    TicketShop: 'TicketShop' as NavigationItemEnum,
    BusinessTicketshop: 'BusinessTicketshop' as NavigationItemEnum,
    LogIn: 'LogIn' as NavigationItemEnum,
    TicketExchangeChild: 'TicketExchangeChild' as NavigationItemEnum,
    Dynamic: 'Dynamic' as NavigationItemEnum,
    MyFlexplan: 'MyFlexplan' as NavigationItemEnum
};

