/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CrossSellProductVariantView { 
    Id?: number;
    Name?: string | null;
    SKU?: string | null;
    VatRateId?: number | null;
    VatRateDescription?: string | null;
    AmountExVat?: number;
    AmountInVat?: number;
    readonly VatAmount?: number;
    CrossSellProductId?: number;
    CrossSellProductName?: string | null;
    CurrentStockCount?: number | null;
    AvailableCount?: number | null;
    SoldCount?: number | null;
}

