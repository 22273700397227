/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventCrossSellProductVariantView { 
    EventCrossSellProductVariantId?: number;
    CrossSellProductVariantId?: number;
    CrossSellProductId?: number;
    EventId?: number;
    EventName?: string | null;
    EventEndDate?: Date;
    InitiativeId?: number;
    CrossSellProductName?: string | null;
    CrossSellProductDescription?: string | null;
    CrossSellProductDescriptionLong?: string | null;
    CrossSellProductVariantName?: string | null;
    AmountExVat?: number;
    AmountInVat?: number;
    readonly VatAmount?: number;
    SalesChannelId?: number;
    OnSaleFrom?: Date | null;
    OnSaleTill?: Date | null;
    AllowStandAlonePurchase?: boolean;
    IncludeAsCrossSellPage?: boolean;
    IncludeInShoppingCart?: boolean;
    FantastixManageableFrom?: Date | null;
    FantastixManageableTill?: Date | null;
    FantastixOnSale?: boolean;
    FantastixOnSaleFrom?: Date | null;
    FantastixOnSaleTill?: Date | null;
    readonly IsFantastixManageable?: boolean;
    CurrentStockCount?: number | null;
    AvailableCount?: number | null;
    SoldCount?: number | null;
}

