<ng-template #NewEventMessage>{{'COMPONENTS.MANAGE-EVENT-SALE-CATEGORY-STATES.NEW-EVENT-MESSAGE' | translate}}</ng-template>

<div *ngIf="tenantShopList.length > 1" class="mb-2">
  <div *ngFor="let tenantShop of tenantShopList" class="d-flex d-inline-flex p-0 m-1" (click)="setActive(tenantShop.Id)">
    <button class="m-0 btn btn-small" [class.btn-primary]="activeTenantShop.Id == tenantShop.Id">{{tenantShop.Name}}</button>
  </div>
</div>

<t4-tabs (selected)="onTabChange($event)">
  <t4-tab *ngFor="let saleCategory of eventSaleCategories" [tabTitle]="saleCategory.Name" [id]="saleCategory.Id"></t4-tab>
</t4-tabs>
  
<div>
  <app-custom-button-state-list  #customButtonStateListComponent></app-custom-button-state-list>
</div>




 