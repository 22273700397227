import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService, DialogHelper, DialogWidth, HttpLoaderService, ListViewSelectionMode } from '@tymes4-shared';
import { EventShopLabelService, TenantShop, TenantShopService } from '../../api';
import { EditLabelDetailsComponent } from './dialogs/edit-label-details/edit-label-details.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-event-label-management',
  templateUrl: 'event-label-management.component.html'
})

export class EventLabelManagementComponent implements OnInit {
  
  @Input() eventId: number;
  @ViewChild('listVw') listVw;

  public isInArchiveMode: boolean = false;
  public tenantShopList: TenantShop[] = [];
  public activeTenantShop: TenantShop;

  public listOptions = {
    search: true,
    showBarcodeSearch: false,
    pageSize: 12,
    noItemsText: this.translate.instant('EVENT-LABEL-MANAGEMENT.EMPTY'),
    searchTitle: this.translate.instant('EVENT-LABEL-MANAGEMENT.SEARCH'),
    multiSelectMode: false,
    selectionMode: ListViewSelectionMode.DirectSelect,
    headers: [
      { sortField: '', label: '', width: 32, overflowHidden: true, hideOn: null }, //icon
      { sortField: 'Type', label: 'EVENT-LABEL-MANAGEMENT.HEADERS.TYPE', width: 120, overflowHidden: true, hideOn: null },
      { sortField: 'Name', label: 'EVENT-LABEL-MANAGEMENT.HEADERS.NAME', width: 180, overflowHidden: true, hideOn: null, isDefaultSort: 'asc' },
      { sortField: 'DisplayText', label: 'EVENT-LABEL-MANAGEMENT.HEADERS.DISPLAY-TEXT', overflowHidden: true, hideOn: null },
      { sortField: null, label: 'EVENT-LABEL-MANAGEMENT.HEADERS.SHOW', width: 70, overflowHidden: true, hideOn: null },
      { sortField: null, label: 'EVENT-LABEL-MANAGEMENT.HEADERS.SHOW-FROM', width: 130, overflowHidden: true, hideOn: null },
      { sortField: null, label: 'EVENT-LABEL-MANAGEMENT.HEADERS.SHOW-TILL', width: 130, overflowHidden: true, hideOn: null },
      { width: 50, overflowHidden: true, hideOn: null }
    ]
  };

  constructor(
    public confirmService: ConfirmService,
    private eventShopLabelService: EventShopLabelService,
    private loader: HttpLoaderService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private tenantShopService: TenantShopService
  ) { }

  ngOnInit() {
    this.tenantShopService.listAllTenantShops().subscribe(tenantShops => {
      this.tenantShopList = tenantShops;
      this.setActive(this.tenantShopList[0].Id);
    });
  }

  onDataRequest(e) {
    if (this.activeTenantShop?.Id) {
      this.eventShopLabelService.searchEventShopLabels(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { ['eventId']: `${this.eventId}`, ['tenantShopId']: `${this.activeTenantShop.Id}`  }).subscribe(resp => {
        this.listVw.Data = resp;
        this.loader.close();
      });
    }
  }

  setActive(tenantShopId: number) {
    this.activeTenantShop = this.tenantShopList.find(x => x.Id == tenantShopId);
    this.listVw.refreshData();
  }

  openDetails(item: any) {
    
    const options = DialogHelper.GetDialogOptions(DialogWidth.lg, { item: item, eventId: this.eventId, activeTenantShopId: this.activeTenantShop.Id });
    const dialogRef: MatDialogRef<any> = this.dialog.open(EditLabelDetailsComponent, options);

    dialogRef.afterClosed().subscribe(saved => {
      if (saved) {
        this.listVw.refreshData();
      }
    });    
  }

  addNew() {
    this.openDetails(null);
  }

  deleteItem(item: any) {
    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE')
    }).subscribe((r: boolean) => {
      if (r) {

        this.loader.open();
        
        this.eventShopLabelService
            .deleteShopLabel(item.Id) 
            .pipe(first())
            .subscribe(d => {
              this.loader.close();
              this.listVw.refreshData();
            });
      }
    });
  }
}
