import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import {ConfirmService, DialogHelper, DialogWidth, SnackbarService} from '@tymes4-shared';
import { EditCrossSellProductEventComponent } from "../../../shared/dialogs/edit-crosssell-product-event/edit-crosssell-product-event.component";
import { CrossSellProductEventType } from "../../../shared/enums/crosssellproduct-event-type";
import {
  CrossSellProductEventService,
  CrossSellProductVariantService,
  EventCrossSellProductViewPagedResult,
  TenantShop,
  TenantShopService
} from "../../../shared/api";
import { AuthService } from "../../../shared/services/auth.service";


@Component({
    selector: 'app-crosssellproduct-event',
    templateUrl: './crosssellproduct-event.component.html',
  })
  export class CrossSellProductEventComponent {
    // for listview
    @ViewChild('crossSellProductEventListVw') channelListVw;

    public pptEventEditListOptions = {
      search: true,
      pageSize: 12,
      renderInCard: false,

      headers: [
        {sortField: 'GroupId', label: 'CROSSSELL.PRODUCT.EVENT.LIST.HEADER.SORT-ORDER', width: 120},
        {sortField: 'ProductName', label: 'CROSSSELL.PRODUCT.EVENT.LIST.HEADER.NAME', width: null, hideOn: null, isDefaultSort: 'asc'},
        {sortField: 'VariantNames', label: 'CROSSSELL.PRODUCT.EVENT.LIST.HEADER.VARIANTS', width: null, hideOn: null},
        {sortField: 'OnSaleFrom', label: 'CROSSSELL.PRODUCT.EVENT.LIST.HEADER.ONSALE-FROM', width: 150, hideOn: null},
        {sortField: 'OnSaleTill', label: 'CROSSSELL.PRODUCT.EVENT.LIST.HEADER.ONSALE-TILL', width: 150, hideOn: null},
        {sortField: null, label: ''},
      ]
    };
    // end for listview

    public isInArchiveMode = this.authService.isInArchiveMode();
    public tenantShopList: TenantShop[] = [];
    public activeTenantShop: TenantShop;

    @ViewChild('pagerComponent') pagerComponent;
    selectedEventId: any;
    selectedEventType: CrossSellProductEventType
    totalObjectCount: any;
    selectedEventName: any;

    @Input('eventId')
    set eventId(id) {
      this.selectedEventId = id;
    }

    @Input('eventType')
    set eventType(eType) {
        this.selectedEventType = eType;
    }

    @Input('eventName')
    set eventName(eName) {
        this.selectedEventName = eName;
    }

    constructor(
        private crossSellProductEventService: CrossSellProductEventService,
        private dialog: MatDialog,
        private snackBar: SnackbarService,
        private confirmService: ConfirmService,
        private crossSellProductVariantService: CrossSellProductVariantService,
        private translate : TranslateService,
        private authService: AuthService,
        private tenantShopService: TenantShopService
    ) { }

    onDataRequest(e) {
      this.getTenantShops().finally(() => {
        this.crossSellProductEventService.crossSellProductEventSearch(e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, { ['eventId']: `${this.selectedEventId}`,['eventType']: `${this.selectedEventType}` }).subscribe((resp: EventCrossSellProductViewPagedResult[]) => {
          let result = resp.find(results => results.Records.some(x => x.TenantShopId == this.activeTenantShop.Id)) ?? {Records: [], TotalRecords: 0}
          this.channelListVw.Data = result;
          this.totalObjectCount = result.TotalRecords;
        });
      })
    }

    async getTenantShops() {
      if (this.activeTenantShop == null) {
        this.tenantShopService.listAllTenantShops().subscribe(result => {
          this.tenantShopList = result;
          this.activeTenantShop = this.tenantShopList[0];
        });
      }
    }

    actionClick(action, item: any = null) {
      if (action == 'add') {
          this.openDialog(null, true);
      } else if (action === 'edit') {
          this.openDialog(item, false);
      } else if (action === 'delete') {
          this.openConfirmDialog(item);
      }
    }

    openDialog(data: any = {}, isNew?) {
    let title = isNew ? this.translate.instant('CROSSSELL.PRODUCT.EVENT.ADD.PRODUCT') : this.translate.instant('CROSSSELL.PRODUCT.EVENT.EDIT.PRODUCTS', {products : this.selectedEventName});

    if(data==null){
        data = {
            EventId: this.selectedEventId,
            EventType: this.selectedEventType,
        }
    }

    var options = DialogHelper.GetDialogOptions(DialogWidth.md, {title: title, payload: data, isNew: isNew, eventId: this.selectedEventId, eventType: this.selectedEventType, activeTenantShop: this.activeTenantShop});
    let dialogRef: MatDialogRef<any> = this.dialog.open(EditCrossSellProductEventComponent, options);

    dialogRef.afterClosed()
        .subscribe(id => {
            if (id) {
                this.channelListVw.refreshData();
                this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.CHANGES-SAVED'), 'GENERIC.SNACKBAR.CLOSE');
            } else {
                return;
            }
        })
    }

    openConfirmDialog(item) {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
          message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE'),
          okonly: false
        }).subscribe((confirmed: boolean) => {

          if (confirmed === true) {
            this.crossSellProductVariantService.deleteByGroupingId(item.GroupingId).subscribe((data: any) => {
              // close this dialog and pass the newly added id to the called
              this.channelListVw.refreshData();
              this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.DELETED'), 'GENERIC.SNACKBAR.CLOSE');
             });
          }
        });

    }

    reOrder(direction,groupingId) {
      this.crossSellProductVariantService.reorderProducts(groupingId, direction).subscribe((data: any) => {

        this.channelListVw.refreshData();
      });
    }

    setActive(tenantShopId) {
      this.activeTenantShop = this.tenantShopList.find(x => x.Id == tenantShopId);
      this.channelListVw.refreshData();
    }
  }
